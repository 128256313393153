<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box :back="true" back-component-name="management.expense.index">
        <template v-slot:title>
          <span>{{ $t(id != null ? 'expense.edit_expense' : 'expense.create_expense') }}</span>
        </template>
        <template v-slot:preview>
          <div class="row d-flex" v-if="expenseProxy">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                 v-for="(value, index) in expenseProxy.translations">
              <text-input
                  :key="index"
                  :title="`${$t('expense.expense_name')}(${getLanguageById(value.language_id).name})`"
                  :model.sync="value.name"
                  :placeholder="`${$t('expense.please_enter_expense_name')}(${getLanguageById(value.language_id).name})`"
                  :is-valid="value != null && value.name.length !== 0 ? true : null"
              ></text-input>
            </div>

            <div class="col-xl-4">
              <div class="d-flex items-end justify-content-start">
                <div>
                  <custom-multi-select
                      :title="$t('expense.expense_logo_name')"
                      :model.sync="logo_id"
                      :options="convertArrayToObjectByKeyForExpense(logoItems, 'logicalref', ['name','description'])"
                      name="expense_logo_name"
                      :not-list="true"
                      :is-inline="true"
                      :max="1"
                      :required="true"
                      :item-per-row="1"
                      input-width="auto"
                      input-max-width="200px"
                  ></custom-multi-select>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <custom-multi-select
                  :title="$t('expense.expense_type') + ':'"
                  :model.sync="type_id"
                  :options="convertArrayToObjectByKey(orderExpenseTypeItems, 'id', 'translations.0.name')"
                  name="name"
                  :not-list="true"
                  :is-inline="true"
                  :max="1"
                  :item-per-row="1"
                  input-width="auto"
                  input-max-width="150px"
              ></custom-multi-select>
            </div>
            <div class="col-xl-4">
              <custom-multi-select
                  :title="$t('expense.expense_category') + ':'"
                  :model.sync="expense_category_id"
                  :options="expense_category"
                  name="ecpense_category"
                  :not-list="true"
                  :is-inline="true"
                  :max="1"
                  :item-per-row="1"
                  input-width="auto"
                  input-max-width="150px"
              ></custom-multi-select>
            </div>

            <div class="col-xl-4">
              <label
                  class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary">
                <input v-model="is_default" type="checkbox" name="" :value="true">
                <span style="border-radius: 8px;"></span>
                {{ $t('general.default') }}
              </label>
              <label
                  class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary ml-2">
                <input v-model="ignore_check_price" type="checkbox" name="" :value="true">
                <span style="border-radius: 8px;"></span>
                {{ $t('general.ignore_check_price') }}
              </label>
              <label
                  class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary ml-2">
                <input v-model="suppliers_can_enter_body_sizes" type="checkbox" name="" :value="true">
                <span style="border-radius: 8px;"></span>
                {{ $t('general.suppliers_can_enter_body_sizes') }}
              </label>


            </div>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button-with-icon
                class="mr-3 danger-border-1px"
                @onClick="$router.go(-1)"
                :text="$t('general.cancel')"
                size="lg"
                :icon-name="null"
            ></button-with-icon>
            <button-with-icon
                @onClick="createOrUpdateExpense"
                :text="$t(id != null ? 'general.save' : 'general.create')"
                size="lg"
                :icon-name="null"
                :disabled="!isValidToCreate"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR, GET_ITEMS,
  ITEMS,
  GET_ITEM_DETAIL_BY_ID,
  LOADING, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {LANGUAGE} from "@/core/services/store/system-options.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
  name: "ExpenseCreateEdit",
  components: {
    ButtonWithIcon,
    TextInput,
    SelectInput,
    CustomMultiSelect,
    DashboardBox
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      expense: CURRENT_ITEM,
      language: LANGUAGE,
      orderExpenseTypeItems: ITEMS,
    }),
    isValidToCreate() {
      if (this.expense == null || this.expense.translations == null || this.logo_id == null|| this.type_id == null || this.expense.translations.length === 0) return false;

      for (let i = 0; i < this.expense.translations.length; i++) {
        if (this.expense.translations[i].name == null || this.expense.translations[i].name.length === 0) {
          return false;
        }
      }

      return true;
    },
    expense_category(){
      let expanse_category_ids={1:this.$t('expense.expenses'),2:this.$t('expense.general_card')}
      return expanse_category_ids;
    },
    expenseProxy: {
      get() {
        if (this.id != null && this.expense) {
          this.type_id = this.expense.type_id;
          this.logo_name = this.expense.logo_name;
          this.logo_id = this.expense.logo_id;
          this.is_default = +this.expense.is_default;
          this.ignore_check_price = +this.expense.ignore_check_price;
          this.expense_category_id = this.expense.expense_category;
          this.logoItems = this.expense.cache_items;
          this.suppliers_can_enter_body_sizes = this.expense.suppliers_can_enter_body_sizes;
        }

        return this.expense;
      },
      set(value) {
        this.setExpense(value);
      }
    }
  },
  data() {
    return {
      id: null,
      languages: [],
      logoItems: [],
      logo_id: this.id ? this.expenseProxy.logo_id : null,
      logo_name: this.id ? this.expenseProxy.logo_name : null,
      type_id: this.id ? this.expenseProxy.type_id : null,
      is_default: this.id ? +this.expenseProxy.is_default : false,
      expense_category_id:this.id ? this.expenseProxy.expense_category : null,
      ignore_check_price: this.id ? +this.expenseProxy.ignore_check_price : false,
      suppliers_can_enter_body_sizes:this.id ? this.expenseProxy.suppliers_can_enter_body_sizes:null,
      tempItem: {
        translations: []
      },
    };
  },
  methods: {
    ...mapMutations({
      setExpense: SET_CURRENT_ITEM,
    }),
    getOrderExpenseType() {
      let payload = {
        url: 'api/order-expense-item-types',
      }

      this.$store.dispatch(GET_ITEMS, payload)
    },
    getCacheData () {
      let payload = {
        url: 'api/expenses/cache-data',
        returnType: "stateless"
      }

      this.$store.dispatch(GET_ITEMS, payload).then(response => {

        if (response.status) {
          this.logoItems = response.data;
        }
      })
    },
    createOrUpdateExpense() {
      let contents = [];
      this.expenseProxy.translations.forEach(item => {
        contents.push(item)
      })

      // If expense_category 1 is expenses, if 2 is general expense code
      let payload = {
        url: this.id == null ? 'api/expenses' : 'api/expenses/' + this.id,
        id: this.id,
        contents: {
          translations: this.expenseProxy.translations,
          type_id: this.type_id,
          logo_id: this.logo_id,
          is_default: this.is_default,
          ignore_check_price: this.ignore_check_price,
          logo_name:this.logo_name,
          expense_category:this.expense_category_id,
          suppliers_can_enter_body_sizes:this.suppliers_can_enter_body_sizes
        },
        redirectRouteName: 'management.expense.index',
        successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
      }
      this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);
    },
  },
  mounted() {
    this.id = this.$route.params.id;

    if (this.id != null) {
      let payload = {
        url: 'api/expenses/' + this.id,
        id: this.id,
        redirectRouteOnNotFound: 'management.expense.index'
      }

      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload)
    }

    this.getOrderExpenseType();
    this.getCacheData();

    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("expense.expenses"), route: "management.expense.index"},
      {title: this.$t(this.id != null ? "expense.edit_expense" : "expense.create_expense")},
    ]);

    this.languages = this.convertArrayToObjectByKey((this.systemLanguages), 'id', 'name');

    if(!this.id){
      let languages = this.systemLanguages;
      let self = this;

      languages.forEach(item => {
        self.tempItem.translations.push({
          language_id: item.id,
          name: "",
        })
      })
      this.setExpense(self.tempItem)
    }
  }
}
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
